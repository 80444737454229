.firebaseui-container {
    background: rgba(20, 20, 20, 1);
    margin-bottom: 15px;
    min-height: 150px;
    padding-top: 10px;
    border-radius: 20px;
    box-shadow: none;
  }

  .firebaseui-container.firebaseui-page-provider-sign-in {
    background: rgba(20, 20, 20, 1);
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }